import { Navigate } from "react-router-dom";
import Dashboard from "../modules/dashboard/DashBoard";


const PrivateRoute = () => {
  let authToken = localStorage.getItem("authTokens")
                      ? JSON.parse(localStorage.getItem("authTokens"))
                      : null
  // determine if authorized, from context or however you're doing it

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  
  if(authToken != null ){
    let exp = new Date(authToken.token.expires_at)
    let now = new Date();
    if(now > exp){
      localStorage.removeItem("authTokens");
      localStorage.removeItem("site");
      localStorage.removeItem("user");
      localStorage.removeItem("user_type");
      localStorage.removeItem("connection");
      authToken = null;
    }
  }

  return authToken !== null ? <Dashboard /> : <Navigate to="/login" />;
};
export default PrivateRoute;
