import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import SimpleBar from 'simplebar-react'
import "simplebar/dist/simplebar.min.css";

import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CNavTitle,
  CNavItem,
  CNavGroup,
  CSidebarToggler,
  CImage,
} from "@coreui/react";

import CIcon from "@coreui/icons-react";
import {
  cilPuzzle,
  cilRestaurant,
  cilUserFemale,
  cilMoney,
  cilUser,
  cilStorage,
  cibGoogleAnalytics,
  cilHome,
  cilHamburgerMenu,
  cilFastfood,
  cilHistory,
  cilGroup,
  cilPin,
} from "@coreui/icons";
import { useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import { useState } from 'react';

const AppSidebar = () => {
  const { serverUrl, siteUser } = useContext(AuthContext);
  const [logoUrl, setLogoUrl] = useState("");
  const [siteName, setSiteName] = useState("");
  const dispatch = useDispatch();
  const unfoldable = useSelector((state) => state.sidebarUnfoldable);
  const sidebarShow = useSelector((state) => state.sidebarShow);


  let [userType] = useState(() =>
      localStorage.getItem("user_type")
        ? JSON.parse(localStorage.getItem("user_type"))
        : null
    );

  useEffect(()=>{
    if(siteUser){
      setLogoUrl(serverUrl+'/logos/'+siteUser.logo);
      setSiteName(siteUser.name)
    }
  }, [siteUser])

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: "set", sidebarShow: visible });
      }}
    >
      {!unfoldable ? (
        <CSidebarBrand className="text-sm" to="/">
          <CImage src={logoUrl} className="w-75" size="sm" />
        </CSidebarBrand>
      ) : (
        <CSidebarBrand className="text-sm" to="/">
          <span className="text-lg font-bold">{siteName}</span>
        </CSidebarBrand>
      )}

      <CSidebarNav>
        <SimpleBar>
          <CNavTitle>Navigations</CNavTitle>
          <CNavItem href="/" className={userType.orders || userType.is_admin ? "": "d-none"}>
            <CIcon customClassName="nav-icon" icon={cilRestaurant} />
            Commandes
          </CNavItem>
          <CNavItem href="/service" className={userType.orders || userType.is_admin ? "": "d-none"}>
            <CIcon customClassName="nav-icon" icon={cilUserFemale} />
            Service
          </CNavItem>
          <CNavItem href="/paiement"  className={userType.orders || userType.is_admin ? "": "d-none"}>
            <CIcon customClassName="nav-icon" icon={cilMoney} />
            Paiements
          </CNavItem>
          <CNavItem href="/historique" className={userType.orders || userType.is_admin ? "": "d-none"}>
            <CIcon customClassName="nav-icon" icon={cilHistory} />
            Historique
          </CNavItem>
          <CNavGroup toggler="Menu" className={userType.menu||userType.is_admin ? "": "d-none"}>
            <CNavItem href="/typearticle" className={userType.is_admin ? "": "d-none"}>
              <CIcon customClassName="nav-icon" icon={cilPuzzle} /> 
              Types d'Articles
            </CNavItem>
            <CNavItem href="/typemenu">
              <CIcon customClassName="nav-icon" icon={cilFastfood} /> 
              Types de Menu
            </CNavItem>
            <CNavItem href="/groupmenu">
              <CIcon customClassName="nav-icon" icon={cilHamburgerMenu} />{" "}
              Groupes de Menu
            </CNavItem>
            <CNavItem href="/article">
              <CIcon customClassName="nav-icon" icon={cilFastfood} />
              Articles
            </CNavItem>
            <CNavItem href="/tables">
              <CIcon customClassName="nav-icon" icon={cilRestaurant} /> 
                Tables
            </CNavItem>
          </CNavGroup>
          <CNavGroup toggler="Gestion des Utilisateurs" className={userType.users||userType.is_admin ? "": "d-none"}>
            <CNavItem href="/gestionutilisateur">
              <CIcon customClassName="nav-icon" icon={cilUser} />
              Utilisateurs
            </CNavItem>
            <CNavItem href="/gestiontypeutilisateur" className={userType.is_admin ? "": "d-none"}>
              <CIcon customClassName="nav-icon" icon={cilGroup} /> 
              Type d'Utilisateurs
            </CNavItem>
          </CNavGroup>
          <CNavItem href="/stock" className={userType.is_admin||userType.stock ? "": "d-none"}>
            <CIcon customClassName="nav-icon" icon={cilStorage} />
            Gestion de Stock
          </CNavItem>
          <CNavItem href="/modes-paiement" className={userType.is_admin ? "": "d-none"}>
            <CIcon customClassName="nav-icon" icon={cilPin} />
            Modes de Paiement
          </CNavItem>
          <CNavItem href="/etat-financiers" className={userType.is_admin ? "": "d-none"}>
            <CIcon customClassName="nav-icon" icon={cibGoogleAnalytics} />
            Etats financiers
          </CNavItem>
          <CNavItem href="/sites" className={userType.is_admin ? "": "d-none"}>
            <CIcon customClassName="nav-icon" icon={cilHome} />
            Gestion des sites
          </CNavItem>
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() =>
          dispatch({ type: "set", sidebarUnfoldable: !unfoldable })
        }
      />
    </CSidebar>
  );
};

export default React.memo(AppSidebar);