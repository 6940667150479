class UserType {
    UserType(
        label,
        is_admin,
        stock,
        orders,
        menu,
        users,
        cancel_order
    ){
        this.label = label
        this.is_admin = is_admin
        this.stock = stock
        this.orders = orders
        this.menu = users
        this.cancel_order = cancel_order
    }
}

class User {
    User(
        user_type_id,
        name,
        surname,
        email,
        contact,
        address,
        username,
        password,
        password_confirm
    ){
        this.user_type_id = user_type_id
        this.name = name
        this.surname = surname
        this.email = email
        this.contact = contact
        this.address = address
        this.username = username
        this.password = password ? password : null
        this.password_confirm = password_confirm ? password_confirm : null
    }

}

class Site {
  Site(name, address, currency, contact, email, slogan, immatriculation, logo) {
    this.name = name;
    this.address = address;
    this.currency = currency;
    this.contact = contact;
    this.email = email;
    this.slogan = slogan;
    this.immatriculation = immatriculation;
    this.logo = logo;
  }
}

class Setup {
    Setup(
        user,
        site
    ){
        this.user = user
        this.site = site
    }
}

export {User, Site, Setup}