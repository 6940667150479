import React from 'react'
import { AppContent, AppSidebar, AppFooter, AppHeader } from './components/index'
import { useState } from 'react';

const Dashboard = () => {
  const [pageTitle, setPageTitle] = useState("");

  return (
    <div>
      <AppSidebar />
      <div className="wrapper flex-column min-h-screen bg-light">
        <AppHeader pageTitle={pageTitle} />
        <div className="body flex-grow-1 px-3">
          <AppContent setPageTitle={setPageTitle}/>
        </div>
      </div>
      <AppFooter />
    </div>
  );
}

export default Dashboard
