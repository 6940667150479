const serverUrl = 'http://localhost:8000'

const apiUrl =  serverUrl +'/api'

const defaultHeaders = {
    'Accept' : 'application/json'
}

const authHeaders = {
    ...defaultHeaders
}

export {
    serverUrl,
    apiUrl,
    defaultHeaders,
    authHeaders
}