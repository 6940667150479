import React from "react";
import { BrowserRouter , Route, Routes } from "react-router-dom";

import '@coreui/coreui/dist/css/coreui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Login from "./auth/Login";
import Register from "./auth/Register";
import DashBoard from "./dashboard/DashBoard";
import Page404 from "./errors/Page404";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";

import '../styles/scss/style.scss'
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "../utils/PrivateRoute";
import ResetPassword from "./auth/ResetPassword";

const AppModuleRouter = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route element={<PrivateRoute />} path="/*">
            <Route path="/*" element={<DashBoard />} />
          </Route>

          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />

          <Route path="*" element={<Page404 />} />
        </Routes>
      </AuthProvider>
      <NotificationContainer />
    </BrowserRouter>
  );
};

export default AppModuleRouter;