import { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import '@coreui/coreui/dist/css/coreui.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import digimarket_logo1 from './digimarket_light.png'
import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CImage,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import { useNavigate } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import { cilFile, cilLockLocked, cilPhone, cilUser } from "@coreui/icons";
import { defaultHeaders } from "../../../api.js";
import AuthContext from "../../context/AuthContext.js";

const LoginForm = () => {
  const { deleteTokens, apiUrl } = useContext(AuthContext);
  let navigate = useNavigate();
  const [state, setState] = useState({
    username: "",
    password: "",
  });
  const [controler, setControler] = useState({
    videU: true,
    videP: true,
  });
  const [error, setError] = useState(false);

  const handleInputChange = (event) => {
    event.target.name === "username"
      ? event.target.value !== ""
        ? setControler({ ...controler, videU: false })
        : setControler({ ...controler, videU: true })
      : event.target.value !== ""
      ? setControler({ ...controler, videP: false })
      : setControler({ ...controler, videP: true });

    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    axios({
      url: apiUrl + "/login",
      method: "post",
      headers: defaultHeaders,
      data: { ...state },
    })
      .then((response) => {
        console.log(response.data.auth);
        localStorage.setItem(
          "user_type",
          JSON.stringify(response.data.user_type)
        );
        localStorage.setItem("user", JSON.stringify(response.data.user));
        localStorage.setItem("site", JSON.stringify(response.data.site));
        localStorage.setItem("authTokens", JSON.stringify(response.data.auth));
        localStorage.setItem("connection", JSON.stringify(response.data.connection));

        window.location.reload();
      })
      .catch((e) => {
        setError(true);
        setState({
          username: "",
          password: "",
        });
      });
  };

  useEffect(()=>{
    let authToken = localStorage.getItem("authTokens")
                          ? JSON.parse(localStorage.getItem("authTokens"))
                          : null
    if(authToken != null ){
      let exp = new Date(authToken.token.expires_at)
      let now = new Date();
      if(now > exp){
        deleteTokens()
      }else{
        navigate("/");
      }
    }
  })

  return (
    <div className="bg-white min-vh-100 d-flex flex-row align-items-center">
      <CContainer className="h-full">
        <CRow className="shadow-2xl p-3">
          <CCol md={6} className="h-max">
              <CContainer className="h-full mt-3">
                <CRow>
                  <CRow className="d-block d-md-none">
                    <CContainer className="align-items-center">
                      <CImage src={digimarket_logo1} className="" height="1" fluid rounded></CImage>
                    </CContainer>
                  </CRow>
                  <CRow className="align-items-center text-center">
                    <span className="d-md-none font-bold text-primary text-5xl">WELCOME</span>
                    <span className="d-none d-md-block font-bold text-primary text-7xl">WELCOME</span>
                  </CRow>
                  <CRow className="align-items-center text-center mb-1">
                    <span className="" hidden={error}>Veuillez entrer vos informations pour vous connecter</span>
                    <span className="text-danger font-bold" hidden={!error}>Erreur de connexion. Veuillez corriger vos informations.</span>
                  </CRow>
                  <CRow className="p-5">
                    <CForm onSubmit={handleSubmit}>
                      <CFormLabel htmlFor="username">Nom d'Utilisateur</CFormLabel>
                      <CInputGroup className="mb-3">
                        <CInputGroupText>
                          <CIcon icon={cilUser} />
                        </CInputGroupText>
                        <CFormInput 
                          placeholder="Nom d'utilisateur"
                          value={state.username}
                          name="username"
                          onChange={handleInputChange}
                          autoComplete="off"
                        />
                      </CInputGroup>
                      <CFormLabel htmlFor="username">Mot de Passe</CFormLabel>
                      <CInputGroup className="mb-2">
                        <CInputGroupText>
                          <CIcon icon={cilLockLocked} />
                        </CInputGroupText>
                        <CFormInput
                          type="password"
                          placeholder="Mot de passe"
                          name="password"
                          value={state.password}
                          onChange={handleInputChange}
                        />
                      </CInputGroup>
                      <CRow>
                        <CCol md={6}>&nbsp;</CCol>
                        <CCol md={6} xs={12} className="text-right">
                          <Link to="/reset-password">
                            <CButton color="link" className="px-0">
                              Mot de passe oublié?
                            </CButton>
                          </Link>
                        </CCol>
                      </CRow>
                      <CRow>
                          <CButton color="primary" className="px-4" active size="lg"
                          disabled={controler.videP || controler.videU}
                          type="submit"
                          >
                            Connexion
                          </CButton>
                      </CRow>
                    </CForm>
                  </CRow>
                  <CRow className="p-3 align-items-center text-center">
                    <span className="">Contactez-nous</span>
                    <CCol>
                      <CButton color="danger" className="m-1">
                        <Link to="#" className="text-danger p-2">
                          <CIcon icon={cilFile}></CIcon>
                          Gmail
                        </Link>
                      </CButton>
                      <CButton color="success" className="m-1">
                        <Link to="#" className="text-success p-2">
                          <CIcon icon={cilPhone}></CIcon>
                          Phone
                        </Link>
                      </CButton>
                    </CCol>
                  </CRow>
                </CRow>
              </CContainer>
          </CCol>
          <CCol md={6} className="h-max d-none d-md-block">
              <CContainer className="h-full align-items-center">
                <CImage src={digimarket_logo1} className="w-100 h-full"></CImage>
              </CContainer>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default LoginForm;
