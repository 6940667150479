import React, { useState } from "react";


const Footer = () => (
    <div className="justify-center text-sm font-light text-center bg-white font-lato" height={31} >
        Copyright 2022 © DigitMarket. All rights reserved.
    </div>
);




export default Footer;