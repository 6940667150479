import { useState, useContext } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import digimarket_logo1 from './components/digimarket_light.png'
import {
    CButton,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CFormLabel,
    CImage,
    CRow,
} from "@coreui/react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { NotificationManager } from "react-notifications";

const ResetPassword = () => {
    const { apiUrl } = useContext(AuthContext);
    let navigate = useNavigate();
    const [error, setError] = useState(false)
    const [checked, setChecked] = useState(false)
    const [state, setState] = useState({
        username: null,
        contact: null,
        name: null,
        surname: null,
        token: null,
        password: null,
        password_confirmation: null
    });

    const resetState = () => {
        setState({
            ...state,
            username: null,
            contact: null,
            name: null,
            surname: null
        })
    }

    const handleInputChange = (event) => {
        setState({
            ...state,
            [event.target.name] : event.target.value
        })
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if(!checked){
            axios.post(
                apiUrl+'/reset-password',
                state,
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            ).then((resp)=>{
                setState({
                    ...state,
                    token: resp.data.token
                })
                setChecked(true);
                setError(false);
            }).catch((resp)=> {
                setError(true);
                NotificationManager.error(
                    "Compte introuvable",
                    "Erreur"
                );
            });
        }else{
            if(state.password.length< 6){
                Notification.error(
                    "Mot de passe trop court",
                    "Erreur"
                );
                return;
            }
            if(state.password !== state.password_confirmation){
                Notification.error(
                    "Les deux mots de passe ne correspondent pas!",
                    "Erreur"
                );
                return;
            }
            axios.post(
                apiUrl+'/new-password',
                {
                    token: state.token,
                    password: state.password,
                    password_confirmation: state.password
                },
                {
                    headers: {
                        Accept: "application/json",
                    },
                }
            ).then((resp)=>{
                NotificationManager.success(
                    "Mot de passe modifié avec succes",
                    "Succes"
                );
                navigate("/login")
            }).catch((resp)=> {
                setError(true);
                NotificationManager.error(
                    "Le mot de passe doit avoir au moins 6 caracteres!",
                    "Erreur"
                );
            });
        }
    }

    return (
        <div className="bg-white min-vh-100 d-flex flex-row align-items-center">
            <CContainer className="h-full">
                <CRow className="shadow-2xl p-3">
                    <CCol md={6} className="h-max">
                        <CContainer className="h-full mt-3">
                            <CRow>
                                <CRow className="align-items-center text-center">
                                    <span className="font-bold text-primary text-7xl">DIGIMARKET</span>
                                    <span className="font-bold text-dark text-xl">Mot de passe oubli&eacute;</span>
                                </CRow>
                                <CRow className="align-items-center text-center mb-1">
                                    <span className="" hidden={error}>Veuillez entrer vos informations pour vous confirmer votre identit&eacute;</span>
                                    <span className={checked? "d-none" : "text-danger font-bold"} hidden={!error}>Erreur de verification du compte. Veuillez corriger vos informations.</span>
                                    <span className={checked? "text-danger font-bold" : "d-none"} hidden={!error}>Erreur d'enregistrement. Veuillez essayer &agrave; nouveau</span>
                                </CRow>
                                <CRow className={!checked? "mt-3 p-5" : "d-none"}>
                                    <CForm onSubmit={handleSubmit}>
                                        <CRow className="mb-3" >
                                            <CFormLabel htmlFor="username" className="col-sm-4 col-form-label">
                                                Nom d'utilisateur 
                                            </CFormLabel>
                                            <CCol sm={8}>
                                                <CFormInput type="text" id="username" name="username" required onChange={handleInputChange}/>
                                            </CCol>
                                        </CRow>
                                        <CRow className="mb-3" >
                                            <CFormLabel htmlFor="name" className="col-sm-4 col-form-label">
                                                Nom 
                                            </CFormLabel>
                                            <CCol sm={8}>
                                                <CFormInput type="text" id="name" name="name" required onChange={handleInputChange}/>
                                            </CCol>
                                        </CRow>
                                        <CRow className="mb-3" >
                                            <CFormLabel htmlFor="surname" className="col-sm-4 col-form-label">
                                                Prenom 
                                            </CFormLabel>
                                            <CCol sm={8}>
                                                <CFormInput type="text" id="surname" name="surname" required onChange={handleInputChange}/>
                                            </CCol>
                                        </CRow>
                                        <CRow className="mb-3" >
                                            <CFormLabel htmlFor="contact" className="col-sm-4 col-form-label">
                                                Telephone 
                                            </CFormLabel>
                                            <CCol sm={8}>
                                                <CFormInput type="text" id="contact" name="contact" required onChange={handleInputChange}/>
                                            </CCol>
                                        </CRow>
                                        <CRow className="mt-4">
                                            <CButton color="primary" className="px-4" active size="lg" type="submit">
                                                Confirmer
                                            </CButton>
                                        </CRow>
                                    </CForm>
                                </CRow>
                                <CRow className={checked? "mt-3 p-4" : "d-none"}>
                                    <CForm onSubmit={handleSubmit}>
                                        <CRow className="mb-3" >
                                            <CFormLabel htmlFor="password" className="col-sm-4 col-form-label">
                                                Mot de passe
                                            </CFormLabel>
                                            <CCol sm={8}>
                                                <CFormInput type="password" id="password" name="password" required onChange={handleInputChange}/>
                                            </CCol>
                                        </CRow>
                                        <CRow className="mb-3" >
                                            <CFormLabel htmlFor="password_confirmation" className="col-sm-4 col-form-label">
                                                Confirmation
                                            </CFormLabel>
                                            <CCol sm={8}>
                                                <CFormInput type="password" id="password_confirmation" name="password_confirmation" required onChange={handleInputChange}/>
                                            </CCol>
                                        </CRow>
                                        <CRow className="mt-5 pt-5">
                                            <CButton color="primary" className="px-4" active size="lg" type="submit">
                                                Changer mot de passe
                                            </CButton>
                                        </CRow>
                                    </CForm>
                                </CRow>
                                <CRow>
                                    <CCol md={6} className="d-none d-md-block">&nbsp;</CCol>
                                    <CCol md={6} xs={12} className="text-right mb-4">
                                        <Link to="/login">
                                            <CButton color="link" className="px-0">
                                            Se connecter
                                            </CButton>
                                        </Link>
                                    </CCol>
                                </CRow>
                            </CRow>
                        </CContainer>
                    </CCol>
                    <CCol md={6} className="h-max d-none d-md-block">
                        <CContainer className="h-full align-items-center">
                            <CImage src={digimarket_logo1} className="w-100 h-full"></CImage>
                        </CContainer>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
};

export default ResetPassword;
