import React from 'react';
import AppModuleRouter from './modules/AppModuleRouter';


const App = () => {
  return (
    <AppModuleRouter>

    </AppModuleRouter>
  );
}

export default App;