import React from 'react'
import { CFooter } from '@coreui/react'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

const AppFooter = () => {
  return (
    <div className="fixed-bottom bg-light">
      <div className="mx-auto  text-center text-bold">
        <a href="#" target="_blank" rel="noopener noreferrer">
          DigiMarket
        </a>
        <span className="ms-1">&copy; 2022 GGS.</span>
      </div>
    </div>
  );
}

export default React.memo(AppFooter)
