import { useContext, useState } from "react";
import {
  CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import {
  cilEnvelopeOpen,
  cilLockLocked,
  cilSettings,
  cilUser,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";

import AuthContext from "../../../context/AuthContext";

const AppHeaderDropdown = () => {
  const { logout } = useContext(AuthContext);

  let [user, setUser] = useState(() =>
    localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null
  );

  let getSlug = (user) => {
    return user.name.toLowerCase()+"-"+user.surname.toLowerCase();
  }

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle
        placement="bottom-end"
        className="py-1 bg-blue-500 hover:bg-blue-800  text-white font-bold shadow-md rounded-md"
        caret={false}
      >
        <CIcon icon={cilUser} className="me-2" />
        {user ? user.surname : ""}
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem href={"userprofile/"+getSlug(user)}>
          <CIcon icon={cilUser} className="me-2" />
          Profile
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem onClick={logout}>
          <CIcon icon={cilLockLocked} className="me-2" />
          Deconnexion
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
