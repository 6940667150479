import React from 'react'

const Site = React.lazy(() =>
  import("./modules/dashboard/components/site/Site")
);
const typeElement = React.lazy(() =>
  import("./modules/dashboard/components/menu/TypeElement")
);
const TypeMenu = React.lazy(() =>
  import("./modules/dashboard/components/menu/TypeMenu")
);
const GroupMenu = React.lazy(() =>
  import("./modules/dashboard/components/menu/GroupeMenu")
);
const Stock = React.lazy(() => import("./modules/stock/Stock"));
const Order = React.lazy(() =>
  import("./modules/menu/components/orders/Order")
);
const Service = React.lazy(() =>
  import("./modules/menu/components/orders/Service")
);
const Payment = React.lazy(() =>
  import("./modules/menu/components/orders/Payment")
);
const ElementMenu = React.lazy(() =>
  import("./modules/dashboard/components/menu/ElementMenu")
);
const Tables = React.lazy(() =>
  import("./modules/dashboard/components/menu/Tables")
);
const PaymentModes = React.lazy(()=>
  import("./modules/finance/components/PaymentMode")
);
const Statistics = React.lazy(()=>
  import("./modules/finance/components/Statistics")
);
const Historique = React.lazy(()=>
  import("./modules/menu/components/orders/History")
);

const GestionUtilisateur = React.lazy(() =>
  import("./modules/dashboard/components/utilisateurs/GestionUtilisateur")
);
const GestionTypeUtilisateur = React.lazy(() =>
  import("./modules/dashboard/components/utilisateurs/GestionTypeUtilisateur")
);

const UserProfile = React.lazy(()=>
  import("./modules/dashboard/components/utilisateurs/UserProfile")
);

const routes = [
  { path: "/", exact: true, name: "Commandes", element: Order },
  { path: "/paiement", exact: true, name: "Paiement des Commandes", element: Payment },
  { path: "/service", exact: true, name: "Service des Commandes", element: Service },
  { path: "/historique", exact: true, name: "Historique des Commandes", element: Historique},
  { path: "/sites", exact: true, name: "Manage Sites", element: Site },
  {
    path: "/typearticle",
    exact: true,
    name: "Manage type items",
    element: typeElement,
  },
  {
    path: "/typemenu",
    exact: true,
    name: "Manage type Menu",
    element: TypeMenu,
  },
  {
    path: "/groupmenu",
    exact: true,
    name: "Manage group Menu",
    element: GroupMenu,
  },
  { path: "/stock", exact: true, name: "Gestion de stock", element: Stock },
  {
    path: "/article",
    exact: true,
    name: "Manage element menu",
    element: ElementMenu,
  },
  {
    path: "/tables",
    exact: true,
    name: "Manage house tables",
    element: Tables,
  },
  {
    path: "/modes-paiement",
    exact: true,
    name: "Modes de Paiement",
    element: PaymentModes,
  },
  {
    path: "/etat-financiers",
    exact: true,
    name: "Etats Financiers",
    element: Statistics
  },
  {
    path: "/gestionutilisateur",
    exact: true,
    name: "Manage utilisateur ",
    element: GestionUtilisateur,
  },
  {
    path: "/gestiontypeutilisateur",
    exact: true,
    name: "Manage type utilisateur ",
    element: GestionTypeUtilisateur,
  },
  {
    path: "/userprofile/*",
    exact: false,
    name: "User Profile",
    element: UserProfile
  }
];

export default routes
