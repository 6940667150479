import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CHeaderToggler,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilMenu } from "@coreui/icons";

import { AppHeaderDropdown } from "./header/index";
import shop from "../../../assets/images/shop.png";

const AppHeader = ({pageTitle}) => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state.sidebarShow);

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch({ type: "set", sidebarShow: !sidebarShow })}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/">
          <CIcon src={shop} size="sm" alt="Logo" />
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex m-auto"> 
                <div className="mb-2">
                    <h1 className="text-center text-uppercase">
                        <span className="h2 text-blue-500 font-bold">{ pageTitle }</span>
                    </h1>
                </div>
        </CHeaderNav>
        <CHeaderNav className="ms-3">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
