import { CContainer, CSpinner } from "@coreui/react";
import { Suspense } from "react";
import Footer from "./components/Footer";
import LoginForm from "./components/LoginForm";

const Login = () => {
  return (
    <CContainer fluid>
      <Suspense fallback={<CSpinner color="primary" />}>
        <LoginForm />
        <Footer />
      </Suspense>
    </CContainer>
  );
};

export default Login;
