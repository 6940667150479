import { createContext, useState, useEffect, Children, } from "react";
//et pour concerver eet savoir le chemin react a
 import { useNavigate } from "react-router-dom";
 import axios from "axios";
import { NotificationManager } from "react-notifications";

 const AuthContext = createContext();

 export default AuthContext;

 export const AuthProvider = (props) => {
   let [authTokens, setAuthTokens] = useState(() =>
     localStorage.getItem("authTokens")
       ? JSON.parse(localStorage.getItem("authTokens"))
       : null
   );
   let [user, setUser] = useState(() =>
     localStorage.getItem("user")
       ? JSON.parse(localStorage.getItem("user"))
       : null
   );
   let [site, setSite] = useState(() =>
     localStorage.getItem("site")
       ? JSON.parse(localStorage.getItem("site"))
       : null
   );
   let [userType, setUserType] = useState(() =>
     localStorage.getItem("user_type")
       ? JSON.parse(localStorage.getItem("user_type"))
       : null
   );
   let [loading] = useState(true);

   const [serverUrl] = useState("http://localhost:8000");

   const [imageUrl] = useState(serverUrl + "/images/");
   
   const [apiUrl] = useState(serverUrl + "/api");

   const [defaultHeaders] = useState({
     Accept: "application/json",
   });

   const [token, setToken] = useState(() =>
     localStorage.getItem("authTokens") ? authTokens.accessToken : null
   );

   const history = useNavigate();
   const [contextData, setContextData] = useState({});

   let deleteTokens = () => {
    setAuthTokens(null);
    setUser(null);
    setToken(null);
    setSite(null);
    setUserType(null);
    localStorage.removeItem("authTokens");
    localStorage.removeItem("site");
    localStorage.removeItem("user");
    localStorage.removeItem("user_type");
    localStorage.removeItem("connection");
   }

   let LogoutUser = () => {
    let connection = localStorage.getItem("connection")
                          ? JSON.parse(localStorage.getItem("connection"))
                          : null
     axios.post(
      apiUrl+'/logout',
      {
        token_id: authTokens.token.id,
        connection_id : connection.id
      },
      {
          headers: {
              Accept: "application/json",
              Authorization: `Bearer ${token}`,
          },
      }
     ).then((resp)=>{
        deleteTokens();
  
        history("/login");
     }).catch((resp)=>{
        NotificationManager.error(
          "Erreur de deconnexion de l'utilisateur. Veuillez essayer a nouveau!",
          "Erreur"
        );
     });
   };
   let putToken = (tk) => {
     setToken(tk);
   };

   //automatisation de l'update des tokens ici je
   useEffect(() => {
     setContextData({
       authTokens: authTokens,
       apiUrl: apiUrl,
       imageUrl: imageUrl,
       serverUrl: serverUrl,
       defaultHeaders: defaultHeaders,
       token: token,
       user: user,
       userType: userType,
       siteUser: site,
       setToken: putToken,
       logout: LogoutUser,
       deleteTokens: deleteTokens
     });
   }, [authTokens]);
   
   return (
     <AuthContext.Provider value={contextData}>
       {!loading ? null : props.children}
     </AuthContext.Provider>
   );
 };;;;
