import { useState } from "react";
import { Link } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
  cilLockLocked,
  cilUser,
  cilHome,
  cilPhone,
  cilMoney,
  cilEnvelopeClosed,
  cilSpeaker,
} from "@coreui/icons";
import { User, Site } from "./models.js";
import { Stepper, Step } from "react-form-stepper";

const Register = () => {
  const [user, setUser] = useState(new User());
  const [site, setSite] = useState(new Site());

  const [stpe, setStpe] = useState(1);
  const [controler, setControler] = useState({
    contactSite: false,
    contactUser: false,
    videS: true,
    videU: true,
    password: false,
    confirmPassword: false,
    videpU: true,
    videcU: true,
  });

  let handleChangeUser = (event) => {
    const re = /^\+2376\d{8}$/;
    const re2 = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (event.target.name === "contact") {
      re.test(event.target.value)
        ? setControler({ ...controler, contactUser: true, videU: false })
        : setControler({ ...controler, contactUser: false, videU: false });
    }
    if (event.target.name === "password") {
      re2.test(event.target.value)
        ? setControler({ ...controler, password: true, videpU: false })
        : setControler({ ...controler, password: false, videpU: false });
    }
    if (event.target.name === "password_confirm") {
      event.target.value === user.password
        ? setControler({ ...controler, confirmPassword: true, videcU: false })
        : setControler({ ...controler, confirmPassword: false, videcU: false });
    }
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };
  let handleChangeSite = (event) => {
    const re = /^\+2376\d{8}$/;
    if (event.target.name === "contact") {
      re.test(event.target.value)
        ? setControler({ ...controler, contactSite: true, videS: false })
        : setControler({ ...controler, contactSite: false, videS: false });
    }
    setSite({
      ...site,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer fluid>
        <CRow className="justify-content-center">
          <CCol md={11} lg={10} xl={8}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <div className="grid">
                  <h1 className=" text-center  font-bold ">
                    <span className="text-blue-500 text-2xl ml-2 font-bold">
                      Inscription DigiMarket
                    </span>
                  </h1>
                  <Stepper activeStep={stpe - 1}>
                    <Step label=" INFORMATION BUSINESS " />
                    <Step label="INFORMATION UTILISATEUR" />
                  </Stepper>
                </div>
                <CForm>
                  <CContainer fluid>
                    <CRow className="justify-content-center">
                      <CCol
                        className={`${
                          stpe === 1
                            ? "block transition-all duration-1000 ease-in-out "
                            : "hidden transition-all duration-1000 ease-in-out"
                        }  `}
                        md={10}
                        lg={5}
                        xl={6}
                      >
                        <p className=" text-lg uppercase w-full font-bold text-center text-blue-500 mb-2">
                          Informations du business
                        </p>
                        <CInputGroup className="mb-3">
                          <CInputGroupText>
                            <CIcon icon={cilUser} />
                          </CInputGroupText>
                          <CFormInput
                            placeholder="Nom du site"
                            autoComplete="username"
                            name="name"
                            onChange={handleChangeSite}
                            required
                          />
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                          <CInputGroupText>
                            <CIcon icon={cilHome} />
                          </CInputGroupText>
                          <CFormInput
                            placeholder="Adresse"
                            autoComplete="username"
                            name="address"
                            onChange={handleChangeSite}
                            required
                          />
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                          <CInputGroupText>
                            <CIcon icon={cilMoney} />
                          </CInputGroupText>
                          <CFormInput
                            placeholder="Devise"
                            autoComplete="username"
                            name="currency"
                            onChange={handleChangeSite}
                            required
                          />
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                          <CInputGroupText>
                            <CIcon icon={cilPhone} />
                          </CInputGroupText>
                          <CFormInput
                            placeholder="Telephone"
                            autoComplete="username"
                            name="contact"
                            onChange={handleChangeSite}
                            className={`${
                              controler.contactSite || controler.videS
                                ? ""
                                : "focus:text-red-500 border-red-500"
                            }`}
                            required
                          />
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                          <CInputGroupText>
                            <CIcon icon={cilEnvelopeClosed} />
                          </CInputGroupText>
                          <CFormInput
                            placeholder="Email"
                            autoComplete="email"
                            name="email"
                            onChange={handleChangeSite}
                            required
                          />
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                          <CInputGroupText>
                            <CIcon icon={cilSpeaker} />
                          </CInputGroupText>
                          <CFormInput
                            placeholder="Slogan"
                            autoComplete="username"
                            name="slogan"
                            onChange={handleChangeSite}
                            required
                          />
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                          <CInputGroupText>
                            <CIcon icon={cilLockLocked} />
                          </CInputGroupText>
                          <CFormInput
                            placeholder="Numero d'Immatriculation"
                            autoComplete="username"
                            name="immatriculation"
                            onChange={handleChangeSite}
                            required
                          />
                        </CInputGroup>
                      </CCol>
                      <CCol
                        className={`${
                          stpe === 2
                            ? "block transition-all duration-1000 ease-in-out"
                            : "hidden transition-all duration-1000 ease-in-out"
                        } `}
                        md={10}
                        lg={5}
                        xl={6}
                      >
                        <p className="text-lg w-full text-center font-bold uppercase text-blue-500 mb-2">
                          Informations de l'utilisateur
                        </p>
                        <CInputGroup className="mb-3">
                          <CInputGroupText>
                            <CIcon icon={cilUser} />
                          </CInputGroupText>
                          <CFormInput
                            placeholder="Nom"
                            autoComplete="username"
                            name="name"
                            onChange={handleChangeUser}
                            required
                          />
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                          <CInputGroupText>
                            <CIcon icon={cilUser} />
                          </CInputGroupText>
                          <CFormInput
                            placeholder="Prenom"
                            autoComplete="username"
                            name="surname"
                            onChange={handleChangeUser}
                            required
                          />
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                          <CInputGroupText>
                            {" "}
                            <CIcon icon={cilEnvelopeClosed} />
                          </CInputGroupText>
                          <CFormInput
                            placeholder="Email"
                            autoComplete="email"
                            name="email"
                            onChange={handleChangeUser}
                            required
                          />
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                          <CInputGroupText>
                            <CIcon icon={cilPhone} />
                          </CInputGroupText>
                          <CFormInput
                            placeholder="Telephone"
                            autoComplete="username"
                            name="contact"
                            onChange={handleChangeUser}
                            className={`${
                              controler.contactUser || controler.videU
                                ? ""
                                : "focus:text-red-500 border-red-500"
                            }`}
                            required
                          />
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                          <CInputGroupText>
                            <CIcon icon={cilUser} />
                          </CInputGroupText>
                          <CFormInput
                            placeholder="Nom d'Utilisateur"
                            autoComplete="username"
                            name="username"
                            onChange={handleChangeUser}
                            required
                          />
                        </CInputGroup>
                        <CInputGroup className="mb-3">
                          <CInputGroupText>
                            <CIcon icon={cilLockLocked} />
                          </CInputGroupText>
                          <CFormInput
                            type="password"
                            placeholder="Mot de passe"
                            autoComplete="new-password"
                            name="password"
                            onChange={handleChangeUser}
                            className={`${
                              controler.password || controler.videpU
                                ? ""
                                : "focus:text-red-500 border-red-500"
                            }`}
                            required
                          />
                        </CInputGroup>
                        <CInputGroup className="mb-4">
                          <CInputGroupText>
                            <CIcon icon={cilLockLocked} />
                          </CInputGroupText>
                          <CFormInput
                            type="password"
                            placeholder="Confirmer mot de passe"
                            autoComplete="new-password"
                            name="password_confirm"
                            onChange={handleChangeUser}
                            className={`${
                              controler.confirmPassword || controler.videcU
                                ? ""
                                : "focus:text-red-500 border-red-500"
                            }`}
                            required
                          />
                        </CInputGroup>
                      </CCol>
                      <div className="flex items-center justify-center my-3">
                        {stpe !== 2 ? (
                          ""
                        ) : (
                          <h4
                            onClick={() => setStpe(1)}
                            className="p-1 rounded-md mr-10 bg-gray-300 text-md  hover:bg-blue-300 cursor-pointer uppercase font-lato font-bold text-center"
                          >
                            Precedent
                          </h4>
                        )}
                        {stpe !== 1 ? (
                          ""
                        ) : (
                          <h4
                            onClick={() => setStpe(2)}
                            className="p-1 rounded-md ml-10 bg-gray-300  hover:bg-blue-300 cursor-pointer uppercase text-md font-lato font-bold text-center"
                          >
                            Suivant
                          </h4>
                        )}
                      </div>
                    </CRow>
                  </CContainer>
                  <div className="d-grid ">
                    <CButton
                      disabled={
                        !(
                          controler.confirmPassword &&
                          controler.contactSite &&
                          controler.confirmPassword &&
                          controler.contactUser
                        )
                      }
                      color={`${
                        controler.confirmPassword &&
                        controler.contactSite &&
                        controler.confirmPassword &&
                        controler.contactUser
                          ? "primary"
                          : "light"
                      }`}
                    >
                      Creer un compte
                    </CButton>
                  </div>
                </CForm>
              </CCardBody>
              <CRow>
                <CCol xs={12} className="text-center">
                  <p color="none" className="px-0">
                    Avez vous déjà un compte?
                    <Link className=" ml-2 text-blue-700" to="/login">
                      Connectez-vous ici
                    </Link>
                  </p>
                </CCol>
              </CRow>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Register;
